import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app-config';
import { getParamByParam } from '../../../../node_modules/iso-country-currency/index'


@Injectable({
   providedIn: 'root'
})

export class AuthService {

   public Server = AppConfig.settings.oAuthServer.servername;
   public initialUrlWithApi = this.Server + 'api/';

   public timeout = '6000';
   currencyByCountryName;

   // Church
   // public _getAllChurchList = this.initialUrlWithApi + 'church/getallchurch?active=true';
   public _getChurch = this.initialUrlWithApi + 'church/getmychurch';
   public _addChurchDetail = this.initialUrlWithApi + 'church/save';
   public _updateChurchDetail = this.initialUrlWithApi + 'church/update';
   public _deleteChurchDetail = this.initialUrlWithApi + 'church/deletebyid';
   public _enableChurchDetail = this.initialUrlWithApi + 'church/enableUser';
   // public _activeChurchById = this.initialUrlWithApi + 'church/activatebyid';

   // File Management
   public _uploadChurchLogo = this.initialUrlWithApi + 'filemanagement/uploadchurchLogo';
   public _uploadChurch = this.initialUrlWithApi + 'filemanagement/uploadchurch';
   public _uploadFamily = this.initialUrlWithApi + 'filemanagement/uploadfamily';
   public _uploadFamilyImage = this.initialUrlWithApi + 'filemanagement/uploadfamilyimage';
   public _uploadMember = this.initialUrlWithApi + 'filemanagement/UploadMember';
   public _uploadMemberImage = this.initialUrlWithApi + 'filemanagement/uploadmemberimage';
   public _uploadtransfercertificate = this.initialUrlWithApi + 'filemanagement/uploadtransfercertificate';
   public _uploadfamilytransfercertificate = this.initialUrlWithApi + 'filemanagement/uploadfamilytransfercertificate'
   public _downloadAttachement = this.initialUrlWithApi + 'filemanagement/downloadattachment';
   public _uploadChurchAnnouncement = this.initialUrlWithApi + 'filemanagement/uploadchurchannouncement';
   public _uploadchurchannouncementimage = this.initialUrlWithApi + 'filemanagement/uploadchurchannouncementimage';

   // Cover
   public _getAllCovers = this.initialUrlWithApi + 'cover/getallcovers';
   public _getCoverById = this.initialUrlWithApi + 'cover/getbyid';
   public _addCover = this.initialUrlWithApi + 'cover/save';
   public _updateCover = this.initialUrlWithApi + 'cover/update';
   public _deleteCover = this.initialUrlWithApi + 'cover/deletebyid';
   public _deactivateCover = this.initialUrlWithApi + 'cover/deactivatebyid';
   public _activeCoverById = this.initialUrlWithApi + 'cover/activatebyid';
   public _getcoversbychurchwithmemberdetails = this.initialUrlWithApi + 'cover/getcoversbychurchwithmemberdetails';

   //Cover History
   public _getbycover = this.initialUrlWithApi + 'coverhistory/getbycover';
   public _getbymember = this.initialUrlWithApi + 'coverhistory/getbymember';

   // Dashboard
   public _getFamilyMemberCount = this.initialUrlWithApi + 'dashboard/getfamilymembersummary';
   // public _memberLoginCount = this.initialUrlWithApi + 'dashboard/getMemberAndMemberLoginCount';
   public _getRecentlyAddedMember = this.initialUrlWithApi + 'dashboard/getrecentmemberdetails';
   public _getBirthdayMembers = this.initialUrlWithApi + 'dashboard/getbirthdaymembers';
   public _getWeddingMembers = this.initialUrlWithApi + 'dashboard/getweddingmembers';
   public _getWeeklySubscriptionAmount = this.initialUrlWithApi + 'dashboard/getsubscriptionamount';
   public _getCoverSummary = this.initialUrlWithApi + 'dashboard/getcoversummary';
   public _getBirthdayMemberByYear = this.initialUrlWithApi + 'dashboard/getbirthdaymembersbyyear';
   public _getTopTenExpenses = this.initialUrlWithApi + 'dashboard/gettopexpenses';
   public _getExpenseVsIncome = this.initialUrlWithApi + 'dashboard/getincomevsexpenses';
   public _getMembersbyDeathDate = this.initialUrlWithApi + 'dashboard/getmembersbydeathdate';

   //Smslog type
   public _getAllSmsLogType = this.initialUrlWithApi + 'smslogtype/getallsmslogtype';
   public _getSmsLogTypeId = this.initialUrlWithApi + 'smslogtype/getbyid';
   public _getAllSmsLog = this.initialUrlWithApi + 'smslog/getallsmslogs';

   //Smstatus
   public _getAllSmsSendStatus = this.initialUrlWithApi + 'smssentstatus/getallsmssentstatus'

   // Family
   public _getAllFamilyList = this.initialUrlWithApi + 'family/getallfamilies';
   public _getFamilyListByPaging = this.initialUrlWithApi + 'family/getallfamiliesbypaging';
   public _getFamilybyId = this.initialUrlWithApi + 'family/getbyid';
   public _getFamilybyIdWithSpouse = this.initialUrlWithApi + 'family/getfamilydetailbyid';
   public _changeMaritalStatus = this.initialUrlWithApi + 'member/ChangeMaritalStatus';
   public _addFamilyhDetail = this.initialUrlWithApi + 'family/save';
   public _familyAndSpouseSave = this.initialUrlWithApi + 'family/familyandspousesave'
   public  _getMobileNumber = this.initialUrlWithApi + 'member/mobileNumberValidation'
   public _updateFamilyDetail = this.initialUrlWithApi + 'family/update';
   public _deleteFamilyData = this.initialUrlWithApi + 'family/deletebyid';
   public _deactivateByFamilyId = this.initialUrlWithApi + 'family/deactivatebyid';
   public _activeFamilyById = this.initialUrlWithApi + 'family/activatebyid';
   public _searchByFamily = this.initialUrlWithApi + 'family/getallfamiliesbysearching';
   public _onFamilyHeadChange = this.initialUrlWithApi + 'family/familyHeadChange';
   public _onValidateFormNumber = this.initialUrlWithApi + 'family/validateformnumber';
   public _getCountryCode = 'assets/json/countryCode.json';
   public _addBaptistCertificate = this.initialUrlWithApi + 'BaptismRegister/save';
   public _deleteFamilyBYId = this.initialUrlWithApi + 'family/deleteentirefamilybyid';
   public _activateEntireFamilyById = this.initialUrlWithApi + 'family/ActivateEntireFamilyById';
   public _updateSpouse = this.initialUrlWithApi + 'family/UpdateSpouse';
   // RelationShip
   public _getAllRelationship = this.initialUrlWithApi + 'relationship/getallrelationship';
   public _getRelationshipbyId = this.initialUrlWithApi + 'relationship/getbyid';
   public _addRelationship = this.initialUrlWithApi + 'relationship/save';
   public _updateRelationship = this.initialUrlWithApi + 'relationship/update';
   public _deleteRelationship = this.initialUrlWithApi + 'relationship/deletebyid';
   public _deactivateRelationshipById = this.initialUrlWithApi + 'relationship/deactivatebyid';
   public _activeRelationshipById = this.initialUrlWithApi + 'relationship/activatebyid';
   public _relationshipUpdateSequence = this.initialUrlWithApi + 'relationship/updatesequence';

   // Login
   public _login = this.initialUrlWithApi + 'user/login';

   // Users
   public _getAllUsers = this.initialUrlWithApi + 'user/getallusers';
   public _addUser = this.initialUrlWithApi + 'user/save';
   public _updateUser = this.initialUrlWithApi + 'user/update';
   public _getUserById = this.initialUrlWithApi + 'user/getbyid';

   public _changePassword = this.initialUrlWithApi + 'user/changepassword';
   public _resetPassword = this.initialUrlWithApi + 'user/resetpassword';
   public _userDeleteById = this.initialUrlWithApi + 'user/deletebyid';
   public _userdeactivateById = this.initialUrlWithApi + 'user/deactivatebyid';
   public _userActiveById = this.initialUrlWithApi + 'user/activatebyid';

   // Users - MFA
   public _getMfaCredentials = this.initialUrlWithApi + 'user/getmfacredentials';
   public _validatemfacredentials = this.initialUrlWithApi + 'user/validatemfacredentials';
   public _getUserProfile = this.initialUrlWithApi + 'user/getuserprofile';
   public _switchMfaStatus = this.initialUrlWithApi + 'user/switchmfastatus';

   // Members
   public _getAllMembers = this.initialUrlWithApi + 'member/getallmembers';
   public _getMemberListByPaging = this.initialUrlWithApi + 'member/getallmembersbypaging'
   public _getMemberByFamily = this.initialUrlWithApi + 'member/getmembersbyfamily';
   public _getMemberById = this.initialUrlWithApi + 'member/getbyid';
   public _addMembers = this.initialUrlWithApi + 'member/save';
   public _updateMember = this.initialUrlWithApi + 'member/update';
   public _deleteMemberById = this.initialUrlWithApi + 'member/deletebyid';
   public _activeMemberById = this.initialUrlWithApi + 'member/activatebyid';
   // public _uploadMemberImage = this.initialUrlWithApi + 'member/uploadmemberimage';
   public _importMember = this.initialUrlWithApi + 'member/importmember';
   public _deactivateMemberById = this.initialUrlWithApi + 'member/deactivatebyid';
   public _searchByMember = this.initialUrlWithApi + 'member/getallmembersbysearching';
   public _searchByNonMember = this.initialUrlWithApi + 'Donation/GetDonationMemberSearch';
   public _quicksaveFamily = this.initialUrlWithApi + 'member/quicksaveforfamilyandmember';
   public _transferMember = this.initialUrlWithApi + 'member/TransferMember';
   public _removeMember = this.initialUrlWithApi +'member/RemoveSpouseDetails';
   public _getSpouse = this.initialUrlWithApi + 'member/getbyidwithspouse';
   
   //Member IMPORT EXEFILE
   public _allExeFile =this.initialUrlWithApi + "importExeFile/getall";

   //Member Online Registry
   public _getAllMemberOnlineRegistry = this.initialUrlWithApi + 'memberonlineregistry/getallmemberonlineRegistry';
   public _onlineRegistryMemberApproval = this.initialUrlWithApi + 'memberonlineregistry/MemberApproval';

   //Member Role
   public _getMemberRoleById = this.initialUrlWithApi + 'memberrole/getbyid';
   public _getAllMemberRole = this.initialUrlWithApi + 'memberrole/getallmemberrole';

   //MemberPrayerPoint
   public _getMemberPrayertByDate = this.initialUrlWithApi + 'memberprayerpoint/getbydate';
   public _getMemberPrayerCount = this.initialUrlWithApi + 'memberprayerpoint/getcount';
   public deleteMemberPrayerRequest = this.initialUrlWithApi + 'memberprayerpoint/delete'

   // Offering-Type
   public _getAllOfferingType = this.initialUrlWithApi + 'offeringtype/getallofferingtype';
   public _getUnmappedOfferingType = this.initialUrlWithApi + 'offeringtype/getunmappedofferingtype';
   public _getOfferingTypeById = this.initialUrlWithApi + 'offeringtype/getbyid';
   public _addOfferingType = this.initialUrlWithApi + 'offeringtype/save';
   public _updateOfferingType = this.initialUrlWithApi + 'offeringtype/update';
   public _deleteOfferingTypeById = this.initialUrlWithApi + 'offeringtype/deletebyid';
   public _deactivateOfferingTypeById = this.initialUrlWithApi + 'offeringtype/deactivatebyid';
   public _ActiveOfferingTypeById = this.initialUrlWithApi + 'offeringtype/activatebyid';
   public _offeringUpdateSequence = this.initialUrlWithApi + 'offeringtype/updatesequence';

   // Payment-Type
   public _getAllPaymentType = this.initialUrlWithApi + 'paymenttype/getallpaymenttype';
   public _getPaymentTypeById = this.initialUrlWithApi + 'paymenttype/getbyid';
   public _addPaymentType = this.initialUrlWithApi + 'paymenttype/save';
   public _updatePaymentType = this.initialUrlWithApi + 'paymenttype/update';
   public _deletePaymentTypeById = this.initialUrlWithApi + 'paymenttype/deletebyid';
   public _deactivatePaymentTypeById = this.initialUrlWithApi + 'paymenttype/deactivatebyid';
   public _ActivePaymentTypeById = this.initialUrlWithApi + 'paymenttype/activatebyid';
   public _paymentUpdateSequence = this.initialUrlWithApi + 'paymenttype/updatesequence';

   //Income Type
   public _getAllIncomeType = this.initialUrlWithApi + 'income/getallincome';
   // Certificates
   public _getAllSacraments = this.initialUrlWithApi + 'sacraments/getallsacraments';
   public _getSacrementCertificatebyMemberId = this.initialUrlWithApi + 'memberSacramentCertificate/GetbyMemberId'
   public _getSacrementbyMemberId = this.initialUrlWithApi + 'memberSacrament/GetbyMemberId'
   public _uploadCertificate = this.initialUrlWithApi + 'filemanagement/uploadsacramentcertificate'
   public _uploadsacramentcertificateimage = this.initialUrlWithApi + 'filemanagement/uploadsacramentcertificateimage'
   public _saveSacrementCertificate = this.initialUrlWithApi + 'memberSacramentCertificate/save'
   public _updateSacrementCertificate = this.initialUrlWithApi + 'memberSacramentCertificate/update'
   public _deleteSacrementCertificate = this.initialUrlWithApi + 'memberSacramentCertificate/deletebyid'
   public _saveSacrements = this.initialUrlWithApi + 'sacraments/save'
   public _deleteSacrements = this.initialUrlWithApi + 'sacraments/deletebyid'
   public _deactivateSacrements = this.initialUrlWithApi + 'sacraments/deactivatebyid'
   public _activateSacrements = this.initialUrlWithApi + 'sacraments/activatebyid'
   public _getBaptistParentType = this.initialUrlWithApi + 'BaptismParentType/getallbaptismParentType'
   public _saveBaptismParent = this.initialUrlWithApi + 'BaptismParent/save'
   public _getByMemberId = this.initialUrlWithApi + 'BaptismRegister/GetbyMemberId'
   public _getAllBaptismRegister = this.initialUrlWithApi + 'BaptismRegister/getallBaptismRegister'
   public _getAllBaptismByPaging = this.initialUrlWithApi + 'BaptismRegister/getAllBaptismRegisterByPaging'
   public _getBaptistById = this.initialUrlWithApi + 'BaptismRegister/GetbyId'
   public _getBaptistParentById = this.initialUrlWithApi + 'BaptismParent/getById'
   public _getbyBaptismRegisterId = this.initialUrlWithApi + 'BaptismParent/GetbyBaptismRegisterId'
   public _updateBaptist = this.initialUrlWithApi + 'BaptismRegister/update'
   public _updateBaptistParent = this.initialUrlWithApi + 'BaptismParent/update'
   public _disableBaptismRegister = this.initialUrlWithApi + 'BaptismRegister/deletebyid'
   public _disableMarriageRegister = this.initialUrlWithApi + 'marriageRegister/deletebyid'

   //Marriage 
   public _getAllMarriage = this.initialUrlWithApi + 'marriageRegister/getallMarriageRegister'
   public _saveRegisterMarriage = this.initialUrlWithApi + 'marriageRegister/save'
   public _updateMarriageRegister = this.initialUrlWithApi + 'marriageRegister/update'
   public _saveMarriageWitness = this.initialUrlWithApi + 'marriageWitness/save'
   public _updateMarriageWitness = this.initialUrlWithApi + 'marriageWitness/update'
   public _getMarriageById = this.initialUrlWithApi + 'marriageRegister/GetbyId'
   public _getAllMarriageByPaging = this.initialUrlWithApi + 'marriageRegister/getAllMarriageRegisterByPaging'
   public _getMarriagewitnessById = this.initialUrlWithApi + 'marriageWitness/GetbyMarriageRegisterId';

   //Accounting - Bank
   public _getAllBankDetails = this.initialUrlWithApi + 'bank/getallbankdetails';
   public _saveBank = this.initialUrlWithApi + 'bank/save';
   public _getBankById = this.initialUrlWithApi + 'bank/getbyid';
   public _updateBank = this.initialUrlWithApi + 'bank/update';
   public _deleteBank = this.initialUrlWithApi + 'bank/delete';

   //Accounting - Account
   public _getAllAccountDetails = this.initialUrlWithApi + 'account/getallaccount';
   public _saveAccountDetails = this.initialUrlWithApi + 'account/save';
   public _deactivateById = this.initialUrlWithApi + 'account/deactivatebyid';
   public _getAccountDetailsById = this.initialUrlWithApi + 'account/GetbyId';
   public _getAllAccountType = this.initialUrlWithApi + 'accounttype/getallaccounttype';

   //Fund Account
   public _getFundAccount = this.initialUrlWithApi + 'fundaccount/getallfundaccount';
   public _saveFundAccount = this.initialUrlWithApi + 'fundaccount/save';
   public _deactivateFund = this.initialUrlWithApi + 'fundaccount/deactivatebyid';
   public _updateFund = this.initialUrlWithApi + 'fundaccount/update';
   public _getSubscriptionToAddToFund = this.initialUrlWithApi + 'subscription/GetSubscriptionToAddToFund';
   public _getDonationToAddToFund = this.initialUrlWithApi + 'donation/getdonationtoaddtofund';
   public _getAuctionToAddToFund = this.initialUrlWithApi + 'auction/GetAuctionToAddToFund';
   public _getOfferingToAddToFund = this.initialUrlWithApi + 'offering/GetOfferingToAddToFund';
   public _saveFundAddedandFund = this.initialUrlWithApi + 'fund/savefundaddedandfund';
   public _saveFund = this.initialUrlWithApi + 'fund/savefund';
   public _isFundAccountMapped = this.initialUrlWithApi + 'fund/isFundAccountMapped ';
   public _getFundAccountincomeDetail = this.initialUrlWithApi + 'fund/getFundAccountincomeDetail';
   public _getFundAvailableToDeposit = this.initialUrlWithApi + 'fund/GetFundAvailableToDeposit';
   public _savedepositaddedanddeposit = this.initialUrlWithApi + 'deposit/savedepositaddedanddeposit ';

   //FundDeposit
   public _getMappedFundAccount = this.initialUrlWithApi + 'fundaccount/getmappedfundaccount';
   public _getDepositReport = this.initialUrlWithApi + 'report/getdepositreport';
   public _getFundAccountCash = this.initialUrlWithApi + 'fund/GetFundAccountCash';
   public _saveDeposit = this.initialUrlWithApi + 'deposit/savedeposit ';

   //CashWithDrawal

   public _saveCashwithdraw = this.initialUrlWithApi + 'cashwithdraw/save  ';
   public _getCashWithDrawReport = this.initialUrlWithApi + 'report/getcashwithdrawreport';
   public _getallcashwithdrawmode = this.initialUrlWithApi + 'cashwithdrawmode/getallcashwithdrawmode';
   //MemberLoginCount 
   public _getMemberLoginCount = this.initialUrlWithApi + 'memberloginevent/getMemberAndMemberLoginCount'
   public _memberLoginCount = this.initialUrlWithApi + 'memberloginevent/getMemberAndMemberLoginCount';
   // Subscription-Type
   public _getaSubscription = this.initialUrlWithApi + 'subscription/getasubscription';
   public _getAllSubscriptionType = this.initialUrlWithApi + 'SubscriptionType/getallsubscriptiontype';
   public _getUnmappedSubscriptionType = this.initialUrlWithApi + 'subscriptiontype/getunmappedsubscriptiontype';
   public _getSubscriptionTypeById = this.initialUrlWithApi + 'SubscriptionType/getbyid';
   public _addSubscriptionType = this.initialUrlWithApi + 'SubscriptionType/save';
   public _updateSubscriptionType = this.initialUrlWithApi + 'SubscriptionType/update';
   public _deleteSubscriptionTypeById = this.initialUrlWithApi + 'SubscriptionType/deletebyid';
   public _deactivateSubscriptionTypeById = this.initialUrlWithApi + 'SubscriptionType/deactivatebyid';
   public _ActiveSubscriptionTypeById = this.initialUrlWithApi + 'subscriptiontype/activatebyid';
   public _subscriptiontypeUpdateSequence = this.initialUrlWithApi + 'subscriptiontype/updatesequence';
   public linkSubscriptionToHarvest = this.initialUrlWithApi + 'subscriptiontype/linktoharvest'
   public _removelinktoHarvest = this.initialUrlWithApi + 'subscriptiontype/removelinktoharvest';

   // Subscription
   public _getAllSubscription = this.initialUrlWithApi + 'subscription/getallsubscription';
   public _getSubscriptionById = this.initialUrlWithApi + 'subscription/getsubscriptionbycoverid';
   public _addSubscription = this.initialUrlWithApi + 'subscription/save';
   public _updateSubscription = this.initialUrlWithApi + 'subscription/update';
   public _deleteSubscriptionById = this.initialUrlWithApi + 'subscription/deletebyid';
   public _deleteSubscription = this.initialUrlWithApi + 'subscription/deletesubscriptions';
   public _deactivateSubscriptionById = this.initialUrlWithApi + 'subscription/deactivatebyid';
   public _deactivateSubscriptionByUniqueKey = this.initialUrlWithApi + 'subscription/deactivatebyuniquekey'
   public _importSubscription = this.initialUrlWithApi + 'subscription/importsubscription';
   public _ActiveSubscriptionById = this.initialUrlWithApi + 'subscription/activatebyid';
   public _getSubscriptionByMember = this.initialUrlWithApi + 'subscription/getallsubscriptionbymember';
   public getAllSUbscriptionByPaging = this.initialUrlWithApi + 'subscription/getallsubscriptionbypaging';
   public getMemberSubscriptionByMonth = this.initialUrlWithApi + 'subscription/getmembersubscriptionsbymonth';


   // Auction
   //public _AuctionByMember = this.initialUrlWithApi + 'auction/getbymember';
   public _getAuctionDetail = this.initialUrlWithApi + 'auction/getanauctiondetail';

   //Auction Type
   public _getAllAuctiontype = this.initialUrlWithApi + 'auctiontype/getallauctiontype';
   public _getUnmappedAuctiontype = this.initialUrlWithApi + 'auctiontype/GetUnMappedAuctionType';

   public _getAuctiontypeById = this.initialUrlWithApi + 'auctiontype/getbyid';
   public _saveAuctiontype = this.initialUrlWithApi + 'auctiontype/save';
   public _updateAuctiontype = this.initialUrlWithApi + 'auctiontype/update';
   public _deleteAuctiontype = this.initialUrlWithApi + 'auctiontype/deletebyid';
   public _auctionTypeDeactivatebyid = this.initialUrlWithApi + 'auctiontype/deactivatebyid';
   public _auctionTypeActivatebyid = this.initialUrlWithApi + 'auctiontype/activatebyid';
   public _auctionTypeUpdatesequence = this.initialUrlWithApi + 'auctiontype/updatesequence';

   // AuctionItem
   public _getAllAuctionItem = this.initialUrlWithApi + 'auctionitem/getallauctionitem';
   public _AuctionByMember = this.initialUrlWithApi + 'auctionitem/getauctionitems';
   // public _getAllOutstandingDetails = this.initialUrlWithApi + 'auctionitem/getalloutstandingdetails';
   public _getAllOutstandingDetails = this.initialUrlWithApi + 'auction/getauctionoutstandingdetailsbypaging';
   public _addAcutionItem = this.initialUrlWithApi + 'auctionitem/save';
   public _importHarvestFestival = this.initialUrlWithApi + 'auctionitem/importharvestfestivaldetails'

   // AuctionPayment
   public _addAcutionPayment = this.initialUrlWithApi + 'auctionpayment/save';

   // Carol Area
   public _getAllCarolArea = this.initialUrlWithApi + 'carolarea/getallcarolarea';
   public _getCarolAreaList = this.initialUrlWithApi + 'carolarea/getallcarolareas';
   public _getAllArea = this.initialUrlWithApi + 'carolarea/getallarea';
   public __getByCarolArea = this.initialUrlWithApi + 'carolarea/getbycarolarea';
   public _getAreaById = this.initialUrlWithApi + 'carolarea/getbyareaid';
   public _addCarolArea = this.initialUrlWithApi + 'carolarea/save';
   public _updateCarolArea = this.initialUrlWithApi + 'carolarea/update';
   public _deleteCarolArea = this.initialUrlWithApi + 'carolarea/deletebyid';
   public _removeCarolArea = this.initialUrlWithApi + 'carolarea/removecarolarea';

   // Footer Verses
   public _getVerses = 'https://beta.ourmanna.com/api/v1/get/'

   // Group
   public _getAllGroup = this.initialUrlWithApi + 'group/getallgroup';
   public _getAllGroupById = this.initialUrlWithApi + 'group/getbyid';
   public _saveGroup = this.initialUrlWithApi + 'group/savegroup';
   public _updateGroup = this.initialUrlWithApi + 'group/update';
   public _sendSMS = this.initialUrlWithApi + 'group/sendsms';
   public _sendindividualsms = this.initialUrlWithApi + 'group/sendindividualsms';
   public _deleteGroup = this.initialUrlWithApi + 'group/delete';
   public getAllNonGroupMembers = this.initialUrlWithApi + 'group/getnongroupmembersbyid'
   
   //Attendance

   public _editAttendance = this.initialUrlWithApi + 'attendance/getchurcheventattendance';
   public _availableattendance = this.initialUrlWithApi + 'attendance/getAttendanceAvaliableMembers'
   // public _viewAttendance = this.initialUrlWithApi + 'attendance/getallattendance';
   public _viewAttendance = this.initialUrlWithApi + 'churchevent/getChurchAttentanceEvent';
   public _deleteAttendance = this.initialUrlWithApi + 'attendance/deleteattendancebyid';
   public _saveAttendance = this.initialUrlWithApi + 'attendance/saveattendance';
   // public _getAttendance = this.initialUrlWithApi + 'attendance/getchurcheventattendnace';
   public _getAllChurchEvent = this.initialUrlWithApi + 'churchevent/getChurchAttentanceEvent'

   // Group member
   public _getAllGroupMembers = this.initialUrlWithApi + 'groupmember/getallgroupmembers';
   public _saveGroupMembers = this.initialUrlWithApi + 'groupmember/savegroupmembers';
   public _deleteGroupMembers = this.initialUrlWithApi + 'groupmember/delete';

   //  Offering
   public _getAllOfferings = this.initialUrlWithApi + 'offering/getalloffering';
   public _getOfferingById = this.initialUrlWithApi + 'offering/getbyid';
   public _saveOfferings = this.initialUrlWithApi + 'offering/save';
   public _updateOfferings = this.initialUrlWithApi + 'offering/update';
   public _deleteOfferings = this.initialUrlWithApi + 'offering/deleteOfferings';
   public deactivateOffering = this.initialUrlWithApi + 'offering/deactivateofferings';
   public getAllOfferingsByPaging = this.initialUrlWithApi + 'offering/getbypaging'

   // Donation
   public _getAllDonation = this.initialUrlWithApi + 'donation/getallDonation';
   public _getDonationById = this.initialUrlWithApi + 'donation/getbyid';
   public _saveDonation = this.initialUrlWithApi + 'donation/save';
   public _updateDonation = this.initialUrlWithApi + 'donation/update';
   public _deleteDonation = this.initialUrlWithApi + 'donation/delete';
   public deactivateDonation = this.initialUrlWithApi + 'donation/deactivatebyid';

   // Donation Type
   public _getAllDonationType = this.initialUrlWithApi + 'donationtype/getalldonationtype';
   public _getUnmappedDonationType = this.initialUrlWithApi + 'donationtype/getunmappeddonationtype';
   public _getDonationTypeById = this.initialUrlWithApi + 'donationtype/getbyid';
   public _addDonationType = this.initialUrlWithApi + 'donationtype/save';
   public _updateDonationType = this.initialUrlWithApi + 'donationtype/update';
   public _deleteDonationTypeById = this.initialUrlWithApi + 'donationtype/deletebyid';
   public _deactivateDonationTypeById = this.initialUrlWithApi + 'donationtype/deactivatebyid';
   public _ActiveDonationTypeById = this.initialUrlWithApi + 'donationtype/activatebyid';
   public _donationUpdateSequence = this.initialUrlWithApi + 'donationtype/updatesequence';

   // Mail
   public _sendMail = this.initialUrlWithApi + 'email/sendmail';
   public _uploadFile = this.initialUrlWithApi + 'email/uploadfile';
   public _getEmailContent = this.initialUrlWithApi + 'emailContent/getEmailContents';
   public _getContentById = this.initialUrlWithApi + 'emailContent/getbyid';
   public _updateEmailContent = this.initialUrlWithApi + 'emailContent/update';

   //SMS
   public sendDonationSms = this.initialUrlWithApi + 'donation/senddonationsms';
   public sendAuctionSms = this.initialUrlWithApi + 'auction/sendauctionsms';
   public sendSmsSubscription = this.initialUrlWithApi + 'subscription/sendsubscriptionsms';
   public sendBirthdayWishes = this.initialUrlWithApi + 'member/sendbirthdaywishes';
   public sendWeddingWishes = this.initialUrlWithApi + 'member/sendweddingWishes';
   public sendBulkSms = this.initialUrlWithApi + 'group/sendbulksms';

   //SMS Template
   public getSmstemplate = this.initialUrlWithApi + 'smstemplate/getallsmstemplate';
   public getSmstemplateById = this.initialUrlWithApi + 'smstemplate/getbyid';

   //CustomSms

   public getAllCustomsmsConfig = this.initialUrlWithApi + 'customsmsconfig/getallcustomsmsconfig';
   public saveCustomSMS = this.initialUrlWithApi + 'customsms/save';
   public updateCustomSms = this.initialUrlWithApi + 'customsms/update';
   public getallCustomSms = this.initialUrlWithApi + 'customsms/getallcustomsms';


   // Mail Setting Connection Type
   public _getConnectionType = this.initialUrlWithApi + 'mailconnectiontype/getallmailsettingconnectiontype';

   // Mail Setting
   public _getMailSettingById = this.initialUrlWithApi + 'mailsetting/getmailsetting';
   public _saveMailSetting = this.initialUrlWithApi + 'mailsetting/save';
   public _updateMailSetting = this.initialUrlWithApi + 'mailsetting/update';
   public _testMailSetting = this.initialUrlWithApi + 'mailsetting/validatemailsetting';

   // Mail Template
   public _getAllTemplate = this.initialUrlWithApi + 'mailtemplate/getallmailtemplate';
   public _getTemplateById = this.initialUrlWithApi + 'mailtemplate/getbyid';
   public _saveTemplate = this.initialUrlWithApi + 'mailtemplate/save';
   public _updateTemplate = this.initialUrlWithApi + 'mailtemplate/update';
   public _deleteTemplate = this.initialUrlWithApi + 'mailtemplate/delete';

   //Church Events
   public getAllChurchEvents = this.initialUrlWithApi + 'churchevent/getallchurchevent';
   public getChurchEventsByDate = this.initialUrlWithApi + 'churchevent/getallchurcheventbydate';
   public saveChurchEvent = this.initialUrlWithApi + 'churchevent/save';
   public updateChurchEvent = this.initialUrlWithApi + 'churchevent/update';
   public deleteChurchEvent = this.initialUrlWithApi + 'churchevent/delete';
   public uploadEventAttachment = this.initialUrlWithApi + 'filemanagement/uploadchurchevent';
   public uploadchurcheventimage = this.initialUrlWithApi + 'filemanagement/uploadchurcheventimage';

   // Church Config
   public _getMyChurchConfig = this.initialUrlWithApi + 'churchconfig/getmychurchconfig';
   public _getAllChurchConfig = this.initialUrlWithApi + 'churchconfig/getallChurchConfig';
   public _getChurchConfigById = this.initialUrlWithApi + 'churchconfig/getbyid';
   public _saveChurchConfig = this.initialUrlWithApi + 'churchconfig/save';
   public _updateChurchConfig = this.initialUrlWithApi + 'churchconfig/update';
   public _saveAuction = this.initialUrlWithApi + 'auctionitem/save';
  //add aution

   // Church Announcement
   public _getAllChurchAnnouncements = this.initialUrlWithApi + 'churchannouncement/getallchurchannouncements';
   public _getChurchAnnouncementById = this.initialUrlWithApi + 'churchannouncement/getbyid';
   public _saveChurchAnnouncement = this.initialUrlWithApi + 'churchannouncement/save';
   public _updateChurchAnnouncement = this.initialUrlWithApi + 'churchannouncement/update';
   public _deleteChurchAnnouncement = this.initialUrlWithApi + 'churchannouncement/delete';
   public _deactivateChurchAnnouncementById = this.initialUrlWithApi + 'churchannouncement/deactivatebyid';
   public _ActiveChurchAnnouncementById = this.initialUrlWithApi + 'churchannouncement/activatebyid';

   // Report
   public getFamilyReport = this.initialUrlWithApi + 'report/getfamilyheadreport';
   public getMemberReport = this.initialUrlWithApi + 'report/getmemberreport';
   public getSubscriptionReport = this.initialUrlWithApi + 'report/getsubscriptionreport';
   public getOfferingReport = this.initialUrlWithApi + 'report/getofferingreport';
   
   public getSubscriptionLogReport = this.initialUrlWithApi + 'SubscriptionTableEvent/GetAll';
   public getSubscriptionNonPayersReport = this.initialUrlWithApi + 'report/getnonpayeessubscriptionreport';
   public getDonationLogReport = this.initialUrlWithApi + 'donationtableevent/getalldonationtableevents';
   public getExpenseReport = this.initialUrlWithApi + 'report/getexpensereport';
   public getDonationReport = this.initialUrlWithApi + 'Report/getdonationreport';

   // License
   public getLicenseDetails = this.initialUrlWithApi + 'church/getmychurchlicensedetail';

   //Member Events
   public getMemberTableEvents = this.initialUrlWithApi + 'membertableevent/getallmembertableevents';

   //Events
   public getAllEventTypes = this.initialUrlWithApi + 'eventtype/geteventtypes';


   //Bank 
   public getAllBank = this.initialUrlWithApi + 'bank/getallbankdetails';
   //public getBankDetailsById = this.initialUrlWithApi + 'bank/getbyid';
   public _addBankType = this.initialUrlWithApi + 'bank/save';
   //public _updateBankType = this.initialUrlWithApi + 'bank/update';
   public _deactivateBankById = this.initialUrlWithApi + 'bank/deactivatebyid';
   public _activateBankById = this.initialUrlWithApi + 'bank/activatebyid';
   public _deleteBankType = this.initialUrlWithApi + 'bank/delete';
   public _updateSequenceBankType = this.initialUrlWithApi + 'bank/updatesequence'


   //AccountType
   public getAllAccountType = this.initialUrlWithApi + 'accounttype/getallaccounttype';
   public getAccountTypeById = this.initialUrlWithApi + 'accounttype/getbyid';
   public addAccountType = this.initialUrlWithApi + 'accounttype/save';
   // public updateAccountType = this.initialUrlWithApi + 'accounttype/update';
   public deactivateAccountTypeById = this.initialUrlWithApi + 'accounttype/deactivatebyid';
   public ActiveAccountTypeById = this.initialUrlWithApi + 'accounttype/activatebyid';
   public accountTypeUpdateSequence = this.initialUrlWithApi + 'accounttype/updatesequence';
   public deleteAccountTypeById = this.initialUrlWithApi + 'accounttype/delete';

   //Account
   public _getAllAccount = this.initialUrlWithApi + 'account/getallaccount';
   public _getAccountById = this.initialUrlWithApi + 'account/GetbyId';
   public _saveAccount = this.initialUrlWithApi + 'account/save';
   public _deactivateAccount = this.initialUrlWithApi + 'account/deactivatebyid';
   public _activateAccount = this.initialUrlWithApi + 'account/activatebyid';
   public _updateAccount = this.initialUrlWithApi + 'account/Update';

   //Expense List 
   public _getAllExpenseList = this.initialUrlWithApi + 'expense/getexpenselist';
   public _saveExpenseList = this.initialUrlWithApi + 'expense/save';
   public _updateExpenseList = this.initialUrlWithApi + 'expense/update';
   public _getExpenseListById = this.initialUrlWithApi + 'expense/getbyid';
   public _uploadReceiptImage = this.initialUrlWithApi + 'filemanagement/uploadexpensereceiptimage';
   public _uploadexpensereceiptimageinlocal = this.initialUrlWithApi + 'filemanagement/uploadexpensereceiptimageinlocal';
   public _deactivateExpenseList = this.initialUrlWithApi + '/expense/deactivatebyid';
   public _activateExpenseList = this.initialUrlWithApi + 'expense/activatebyid';

   //Expense Type
   public _getExpenseType = this.initialUrlWithApi + 'expensetype/getallexpensetype';

   //Expense Sub Type
   public _getExpenseSubType = this.initialUrlWithApi + 'expensesubtype/getallexpensesubtype';

   //ExpenseType
   public _getAllExpenseType = this.initialUrlWithApi + 'expensetype/getallexpensetype';
   public _getExpenseTypeById = this.initialUrlWithApi + 'expensetype/getbyid';
   public _saveExpenseType = this.initialUrlWithApi + 'expensetype/save';
   public _updateExpenseType = this.initialUrlWithApi + 'expensetype/update';
   public _deleteExpenseType = this.initialUrlWithApi + 'expensetype/deletebyid';

   //ExpenseSubType
   public _getAllExpenseSubType = this.initialUrlWithApi + 'expensesubtype/getallexpensesubtype';
   public _getExpenseSubTypeById = this.initialUrlWithApi + 'expensesubtype/getbyid';
   public _saveExpenseSubType = this.initialUrlWithApi + 'expensesubtype/save';
   public _updateExpenseSubType = this.initialUrlWithApi + 'expensesubtype/update';
   public _deleteExpenseSubType = this.initialUrlWithApi + 'expensesubtype/deletebyid';
   public _getExpenseTypebyExpenseTypeid = this.initialUrlWithApi + 'expensesubtype/getexpensetypebyid';

   //Hobbies
   public _getAllHobbies = this.initialUrlWithApi + 'Hobbies/getallhobbies';
   public _saveHobbies = this.initialUrlWithApi + 'Hobbies/save';
   public _updateHobbies = this.initialUrlWithApi + 'Hobbies/updateSequence';
   public _deactivateHobbbiesById = this.initialUrlWithApi + 'Hobbies/deactivatebyid';
   public _activateHobbiesById = this.initialUrlWithApi + 'Hobbies/activatebyid';
   public _editHobbies = this.initialUrlWithApi + 'Hobbies/update';
   public _deleteHobbies = this.initialUrlWithApi + 'Hobbies/DeleteById';
   //Ministrial Interest

   public _getAllMinistrialInterest = this.initialUrlWithApi + 'MinsterialInterest/getallministerialInterest';
   public _saveMinistrialInterest = this.initialUrlWithApi + 'MinsterialInterest/save';
   public _updateMinistrialInterest = this.initialUrlWithApi + 'MinsterialInterest/updateSequence';
   public _deactivateMinistryById = this.initialUrlWithApi + 'MinsterialInterest/deactivatebyid';
   public _activateMinistryById = this.initialUrlWithApi + 'MinsterialInterest/activatebyid';
   public _updateMinistry = this.initialUrlWithApi + 'MinsterialInterest/update';
   public _deleteMinistrialInterest = this.initialUrlWithApi +  'MinsterialInterest/deletebyid';
   user: Observable<firebase.User>;
   userData: any;
   isLoggedIn = false;
   // churchId;
   tokenId: any;

   constructor(private firebaseAuth: AngularFireAuth,
      private router: Router, private http: HttpClient,
      private toastr: ToastrService) {
      this.user = firebaseAuth.authState;
   }

   getLocalStorageUser() {
      this.userData = JSON.parse(localStorage.getItem('userProfile'));
      if (this.userData) {
         this.isLoggedIn = true;
         return true;
      } else {
         this.isLoggedIn = false;
         return false;
      }
   }

   getOptionsWithFormDataAsRequestFormat(): Object {
      return {
         headers: new HttpHeaders().set('enctype', 'multipart/form-data'),
         responseType: 'text'
      };
   }

   signupUserProfile(value) {
      this.firebaseAuth
         .auth
         .createUserWithEmailAndPassword(value.email, value.password)
         .then(sign => {
            this.toastr.success('Successfully Signed Up!');
            this.setLocalUserProfile(sign);
            this.router.navigate(['/']);
         })
         .catch(err => {
            this.toastr.error(err.message);
         });
   }

   loginUser(value) {
      this.firebaseAuth
         .auth
         .signInWithEmailAndPassword(value.email, value.password)
         .then(user => {
            this.setLocalUserProfile(user);
            this.toastr.success('Successfully Logged In!');
            this.router.navigate(['/']);
         })
         .catch(err => {
            this.toastr.error(err.message);
         });
   }

   resetPassword(value) {
      this.firebaseAuth.auth.sendPasswordResetEmail(value.email)
         .then(reset => {
            this.toastr.success('A password reset link has been sent to this email.');
            this.router.navigate(['/login']);
         })
         .catch(err => {
            this.toastr.error(err.message);
         });
   }

   // logOut() {
   //    this.firebaseAuth
   //       .auth
   //       .signOut();
   //    this.router.navigate(['/login']);
   //    localStorage.removeItem('userProfile');
   //    sessionStorage.clear();
   //    console.clear();
   //    this.isLoggedIn = false;
   //    this.router.navigate(['/']);
   // }


   logOut() {
      this.firebaseAuth.auth.signOut().then(() => {
         localStorage.removeItem('userProfile');
         sessionStorage.clear();
         console.clear();
         this.isLoggedIn = false;
         this.router.navigate(['/login']);
      }).catch((error) => {
         console.error('Error during sign out:', error);
        
      });
   }
   

   setLocalUserProfile(value) {
      localStorage.setItem('userProfile', JSON.stringify(value));
      this.getLocalStorageUser();
      this.isLoggedIn = true;
   }

   /*getChurchId() {
      this.churchId = atob(JSON.parse(localStorage.getItem('userProfile')).churchId);
      return this.churchId;
   }*/

   getId() {
      const Id = atob(JSON.parse(localStorage.getItem('userProfile')).id);
      return Id;
   }

   getUserName() {
      const UserName = atob(JSON.parse(localStorage.getItem('userProfile')).userName);
      return UserName;
   }

   getDisplayName() {
      const DisplayName = atob(JSON.parse(localStorage.getItem('userProfile')).displayName);
      return DisplayName;
   }

   getMfaDetails() {
      const MFA = JSON.parse(localStorage.getItem('userProfile')).enableMFA;
      return MFA;
   }

   getRoleId() {
      const RoleID = atob(JSON.parse(localStorage.getItem('userProfile')).roleId);
      return RoleID;
   }

   getUserRole() {
      const UserRole = atob(JSON.parse(localStorage.getItem('userProfile')).userRole);
      return UserRole;
   }

   public getTokenId() {
      this.tokenId = JSON.parse(localStorage.getItem('userProfile')).token;
      return this.tokenId;
   }

   public getCurrency() {
      const countryName = atob(JSON.parse(localStorage.getItem('userProfile')).country);
      if ((countryName !== "null") && (countryName !== "")) {
         const currencySymbol = getParamByParam('countryName', countryName, 'symbol');
         return currencySymbol;
      }
      else {
         const currencySymbol = getParamByParam('countryName', "India", 'symbol');
         return currencySymbol;
      }

   }

}
function res(res: any): any {
   throw new Error('Function not implemented.');
}

