export class MemberList {
    id: number;
    deserialize(input: any) {
       
        let mySpouseList = {};
        let ministerialInterest = [];
        let hobbiesList = [];
        let memberFullName = getSpace(input.firstName);
        //ministerialInterestList = input.memberMinisterialInterests.map((list) => new ministerialInterestJSON().deserialize(list))
         if (input.memberMinisterialInterests) {
            const ministerialInterestList = input.memberMinisterialInterests.forEach(data => {
                if (data.active === true) {
                    let ministrylist = {
                        'ministerialInterestId': data.ministerialInterestId,
                        'ministerialInterest': data.ministerialInterest,
                        'active': data.active
                    }
                    ministerialInterest.push(ministrylist)
                }

            })
        }
        if (input.memberHobbies) {
            const memberHobbiesList = input.memberHobbies.forEach(data => {
                if (data.active === true) {
                    let hobbies = {
                        'hobbiesId': data.hobbiesId,
                        'hobbies': data.hobbies,
                        'active': data.active
                    }
                    hobbiesList.push(hobbies)
                }

            })
        } 
        const middleName = input.middleName ? input.middleName : '';
        const lastName = input.lastName ? input.lastName : '';
       
        const obj = {
            familyId: input.familyId,
            churchId: input.churchId,
            memberCoverNumber: input.memberCoverNumber,
            salutation: input.firstName ? memberFullName.nameArray : '',
            firstName: input.firstName ? memberFullName.fullNameWithSpace + ' ' + memberFullName.dot : '',
            middleName: input.middleName,
            lastName: input.lastName,
            initialName: input.firstName ? memberFullName.nameArray + '. ' + memberFullName.fullNameWithSpace + ' ' + memberFullName.dot : '',
            name: (memberFullName.nameArray !== '') ? memberFullName.nameArray + '. ' + memberFullName.fullNameWithSpace + ' ' + memberFullName.dot + ' ' +
                middleName + ' ' + lastName : memberFullName.fullNameWithSpace + ' ' + middleName + ' ' + lastName,
            gender: input.gender,
            dateOfBirth: input.dateOfBirth,
            maritalStatus: input.maritalStatus,
            maritalStatusId: input.maritalStatusId,
            spouseId: input.spouseId ? input.spouseId : null,
            dateOfMarried: input.dateOfMarried,
            relationshipId: input.relationshipId,
            relationshipName: input.relationshipName,
            baptised: input.baptised,
            baptismDate:input.baptismDate,
            confirmed: input.confirmed,
            occupation: input.occupation,
            familyHead: input.familyHead,
            familyHeadName: input.familyHeadName,
            contactNumber: input.contactNumber ? input.contactNumber : '',
            contactCode: input.contactCode ? input.contactCode : '',
            contactCodeISO: input.contactCodeISO ? input.contactCodeISO : '',
            email: input.email,
            memberImage: input.memberImage != null ? input.memberImage : '',
            memberHobbies: hobbiesList,
            memberMinisterialInterests: ministerialInterest,
            inactiveReason: input.inactiveReason,
            address: input.address ? input.address : '',
            street: input.street ? input.street : '',
            landMark: input.landMark ? input.landMark : '',
            city: input.city ? input.city : '',
            state: input.state ? input.state : '',
            country: input.country ? input.country : '',
            pinCode: input.pinCode ? input.pinCode : '',
            carolAreaName: input.carolArea ? input.carolArea : '',
            area: input.area ? input.area : '',
            id: input.id,
            formNumber: input.formNumber,
            active: input.active,
            spouse: input.spouse ? mySpouseList : null,
            addedOn: input.addDateTime,
            memberRoleId: input.memberRoleId,
            mobileNumber: input.contactNumber,
            aadharNumber:input.aadharNumber,
            reasonForMembership:input.reasonForMembership,
            memberName:input.memberName,
            // phoneNumber:input.phoneNumber,
            phoneNumber:input.phoneNumber,
            coverNumber: input.coverNumber,
        }
        return obj
    }
}


export class MemberLists {
    deserialize(inputs: any, value: number) {

        let mySpouseList = {};
        let sendObj = [];
        inputs.forEach((input) => {
        let ministerialInterest = [];
        let hobbiesList = [];
        let memberFullName = getSpace(input.firstName);
        //ministerialInterestList = input.memberMinisterialInterests.map((list) => new ministerialInterestJSON().deserialize(list))
        if (input.memberMinisterialInterests) {
            const ministerialInterestList = input.memberMinisterialInterests.forEach(data => {
                if (data.active === true) {
                    let ministrylist = {
                        'ministerialInterestId': data.ministerialInterestId,
                        'ministerialInterest': data.ministerialInterest,
                        'active': data.active
                    }
                    ministerialInterest.push(ministrylist)
                }

            })
        }
        if (input.memberHobbies) {
            const memberHobbiesList = input.memberHobbies.forEach(data => {
                if (data.active === true) {
                    let hobbies = {
                        'hobbiesId': data.hobbiesId,
                        'hobbies': data.hobbies,
                        'active': data.active
                    }
                    hobbiesList.push(hobbies)
                }

            })
        }
        const middleName = input.middleName ? input.middleName : '';
        const lastName = input.lastName ? input.lastName : '';
        // if (input.spouseId && value === 1) {
        //     return;
        //  }
         
        //  else if (!input.spouseId && value === 2) { return; }
            sendObj.push({
            familyId: input.familyId,
            churchId: input.churchId,
            memberCoverNumber: input.memberCoverNumber,
            salutation: input.firstName ? memberFullName.nameArray : '',
            firstName: input.firstName ? memberFullName.fullNameWithSpace + ' ' + memberFullName.dot : '',
            middleName: input.middleName,
            lastName: input.lastName,
            initialName: input.firstName ? memberFullName.nameArray + '. ' + memberFullName.fullNameWithSpace + ' ' + memberFullName.dot : '',
            name: (memberFullName.nameArray !== '') ? memberFullName.nameArray + '. ' + memberFullName.fullNameWithSpace + ' ' + memberFullName.dot + ' ' +
                middleName + ' ' + lastName : memberFullName.fullNameWithSpace + ' ' + middleName + ' ' + lastName,
            gender: input.gender,
            // salutation: input.firstName ? memberFullName.nameArray : '',
            // firstName: input.firstName ? memberFullName.fullNameWithSpace : '',
            // middleName: input.middleName,
            // lastName: input.lastName,
            // initialName: input.firstName ? memberFullName.nameArray + '. ' + memberFullName.fullNameWithSpace : '',
            // name: (memberFullName.nameArray !== '') ? memberFullName.nameArray + '. ' + memberFullName.fullNameWithSpace + ' ' +
            //     middleName + ' ' + lastName : memberFullName.fullNameWithSpace + ' ' + middleName + ' ' + lastName,
            // gender: input.gender,
            dateOfBirth: input.dateOfBirth,
            maritalStatus: input.maritalStatus,
            maritalStatusId: input.maritalStatusId,
            spouseId: input.spouseId ? input.spouseId : null,
            dateOfMarried: input.dateOfMarried,
            relationshipId: input.relationshipId,
            relationshipName: input.relationshipName,
            baptised: input.baptised,
            baptismDate:input.baptismDate,
            confirmed: input.confirmed,
            occupation: input.occupation,
            familyHead: input.familyHead,
            familyHeadName: input.familyHeadName,
            contactNumber: input.contactNumber ? input.contactNumber : '',
            contactCode: input.contactCode ? input.contactCode : '',
            contactCodeISO: input.contactCodeISO ? input.contactCodeISO : '',
            email: input.email,
            memberImage: input.memberImage != null ? input.memberImage : '',
            memberHobbies: hobbiesList,
            memberMinisterialInterests: ministerialInterest,
            inactiveReason: input.inactiveReason,
            address: input.address ? input.address : '',
            street: input.street ? input.street : '',
            landMark: input.landMark ? input.landMark : '',
            city: input.city ? input.city : '',
            state: input.state ? input.state : '',
            country: input.country ? input.country : '',
            pinCode: input.pinCode ? input.pinCode : '',
            carolAreaName: input.carolArea ? input.carolArea : '',
            area: input.area ? input.area : '',
            id: input.id,
            formNumber: input.formNumber,
            active: input.active,
            spouse: input.spouse ? mySpouseList : null,
            addedOn: input.addDateTime,
            memberRoleId: input.memberRoleId,
            mobileNumber: input.contactNumber,
            aadharNumber:input.aadharNumber,
            reasonForMembership:input.reasonForMembership
        })
    
    })
        return sendObj;
    }
}
export class MemberJSON {
    deserialize(input: any) {
        const obj = {
            firstName: input.firstName,
            id: input.id,
            familyHead: input.familyHead,
            familyId: input.familyId,
            gender: input.gender, 
            maritalStatus: input.maritalStatus
          
        }
        return obj
    }
}

export class FamilylistJson {
    deserialize(input: any) {
        let memberList = []
        let spouseList = [];
        let familyHeadData, FamilyHeadName, FamilyName;
        let middleName = '';
        let lastName = '';

        if (input && input.members && input.members.length > 0) {
            memberList = input.members.map(list => new MemberList().deserialize(list));
            // spouseList = new MemberLists().deserialize(input.members, 2)
            familyHeadData = input.members.find((mem) => mem.familyHead === true)
        }

        if (familyHeadData) {
            middleName = familyHeadData.middleName ? familyHeadData.middleName : '';
            lastName = familyHeadData.lastName ? familyHeadData.lastName : '';
            FamilyHeadName = getFullNamewithSpace(familyHeadData.firstName);
        } else {
            FamilyHeadName = getFullNamewithSpace(input.name)
        }

        FamilyName = getFullNamewithSpace(input.name);

        const Obj = {
            // churchId: input.churchId,
            // familyHeadName: input.familyHeadName,
            name: input.name,
            postionName: input.name ? FamilyName.nameArray : '',
            familyName: input.name ? FamilyName.fullNameWithSpace : '',
            middleName: middleName || '',
            FamilyFullName: (FamilyName.nameArray !== '') ? FamilyName.nameArray + '. ' + FamilyName.fullNameWithSpace : FamilyName.fullNameWithSpace,
            formNumber: input.formNumber,
            previousChurchName: input.previousChurchName,
            transferCertificateEnclosed: input.transferCertificateEnclosed,
            transferCertificate: input.transferCertificate,
            familyCoverNumber: (memberList && memberList.length > 0) && familyHeadData ? familyHeadData.memberCoverNumber : '',
            familyHeadCoverNumber: input.familyHeadCoverNumber,
            oldCoverNumber: input.oldCoverNumber,
            address: input.address,
            street: input.street,
            landMark: input.landMark ? input.landMark : "",
            city: input.city,
            state: input.state,
            country: input.country,
            contactNumber: input.contactNumber1 ? input.contactNumber1 : '',
            contactCode: input.contactCode1 ? input.contactCode1 : "",
            contactNumber1: input.contactNumber2 ? input.contactNumber2 : "",
            contactCode1: input.contactCode2 ? input.contactCode2 : "",
            contactCodeISO1: input.contactCodeISO1 ? input.contactCodeISO1 : "",
            dateCreated: input.familyMembershipDate,
            pinCode: input.pinCode,
            carolArea: input.carolArea ? input.carolArea : "",
            areaId: input.areaId,
            area: input.area ? input.area : '',
            baptismDate:input.baptismDate,
            members: input.members ? memberList : [],
            spouseList: input.members ? spouseList : [],
            inactiveReason: input.inactiveReason,
            active: input.active,
            id: input.id,
            familyHeadImage: familyHeadData ? familyHeadData.memberImage : '',
            familyHeadName: ((FamilyHeadName.nameArray !== '') ? FamilyHeadName.nameArray + '. ' + FamilyHeadName.fullNameWithSpace : FamilyHeadName.fullNameWithSpace) +
                ' ' + middleName + ' ' + lastName,
            familyImage: input.familyImage,
            occupation: familyHeadData ? familyHeadData.occupation : '',
            addDateTime: input.addDateTime,
            // memberImage:input.memberImage
        }
        
        return Obj;
    }
}
export class FamilyListJson {
    deserialize(input: any) {
    
        let memberList = []
        let spouseList = [];
        let familyHeadData, FamilyHeadName, FamilyName;
        let middleName = '';
        let lastName = '';

        if (input && input.members && input.members.length > 0) {
            memberList = new MemberLists().deserialize(input.members, 1);
            spouseList = new MemberLists().deserialize(input.members, 2);
            familyHeadData = input.members.find((mem) => mem.familyHead === true)
        }

        if (familyHeadData) {
            middleName = familyHeadData.middleName ? familyHeadData.middleName : '';
            lastName = familyHeadData.lastName ? familyHeadData.lastName : '';
            FamilyHeadName = getFullNamewithSpace(familyHeadData.firstName);
        } else {
            FamilyHeadName = getFullNamewithSpace(input.name)
        }

        FamilyName = getFullNamewithSpace(input.name);

        const Obj = {
            // churchId: input.churchId,
            name: input.name,
            postionName: input.name ? FamilyName.nameArray : '',
            middleName: middleName || '',
            familyName: input.name ? FamilyName.fullNameWithSpace : '',
            FamilyFullName: (FamilyName.nameArray !== '') ? FamilyName.nameArray + '. ' + FamilyName.fullNameWithSpace : FamilyName.fullNameWithSpace,
            formNumber: input.formNumber,
            previousChurchName: input.previousChurchName,
            transferCertificateEnclosed: input.transferCertificateEnclosed,
            transferCertificate: input.transferCertificate,
            familyCoverNumber: (memberList && memberList.length > 0) && familyHeadData ? familyHeadData.memberCoverNumber : '',
            familyHeadCoverNumber: input.familyHeadCoverNumber,
            oldCoverNumber: input.oldCoverNumber,
            address: input.address,
            street: input.street,
            landMark: input.landMark ? input.landMark : '',
            city: input.city,
            state: input.state,
            country: input.country,
            contactNumber: input.contactNumber1 ? input.contactNumber1 : '',
            contactCode: input.contactCode1 ? input.contactCode1 : "",
            contactNumber1: input.contactNumber2 ? input.contactNumber2 : "",
            contactCode1: input.contactCode2 ? input.contactCode2 : "",
            contactCodeISO1: input.contactCodeISO1 ? input.contactCodeISO1 : "",
            dateCreated: input.familyMembershipDate,
            pinCode: input.pinCode,
            carolArea: input.carolArea ? input.carolArea : '',
            areaId: input.areaId,
            area: input.area ? input.area : '',
            members: input.members ? memberList : [],
            spouseList: input.members ? spouseList : [],
            inactiveReason: input.inactiveReason,
            active: input.active,
            id: input.id,
            familyHeadImage: familyHeadData ? familyHeadData.memberImage : '',
            familyHeadName: ((FamilyHeadName.nameArray !== '') ? FamilyHeadName.nameArray + '. ' + FamilyHeadName.fullNameWithSpace : FamilyHeadName.fullNameWithSpace) +
                ' ' + middleName + ' ' + lastName,
            familyImage: input.familyImage,
            occupation: familyHeadData ? familyHeadData.occupation : ''
        }
        return Obj;
    }
}

export class FamilyList {
    deserialize(input: any) {
        let FamilyHeadName, FamilyName;
        let middleName = '';
        let lastName = '';

        if (input.member) {
            middleName = input.member.middleName ? input.member.middleName : '';
            lastName = input.member.lastName ? input.member.lastName : '';
            FamilyHeadName = getFullNamewithSpace(input.member.firstName);
        } else {
            FamilyHeadName = getFullNamewithSpace(input.name)
        }

        FamilyName = getFullNamewithSpace(input.name);

        const Obj = {
            // churchId: input.churchId,
            name: input.name,
            postionName: input.name ? FamilyName.nameArray : '',
            middleName: middleName || '',
            familyName: input.name ? FamilyName.fullNameWithSpace : '',
            FamilyFullName: (FamilyName.nameArray !== '') ? FamilyName.nameArray + '. ' + FamilyName.fullNameWithSpace : FamilyName.fullNameWithSpace,
            formNumber: input.formNumber,
            previousChurchName: input.previousChurchName,
            transferCertificateEnclosed: input.transferCertificateEnclosed,
            transferCertificate: input.transferCertificate,
            familyHeadCoverNumber: input.familyHeadCoverNumber,
            oldCoverNumber: input.oldCoverNumber,
            address: input.address,
            street: input.street,
            landMark: input.landMark ? input.landMark : '',
            city: input.city,
            state: input.state,
            country: input.country,
            contactNumber: input.contactNumber1 ? input.contactNumber1 : "",
            contactCode: input.contactCode1 ? input.contactCode1 : "",
            contactCodeISO: input.contactCodeISO1 ? input.contactCodeISO1 : "",
            contactNumber1: input.contactNumber2 ? input.contactNumber2 : "",
            contactCode2: input.contactCode2 ? input.contactCode2 : "",
            dateCreated: input.familyMembershipDate,
            pinCode: input.pinCode,
            carolArea: input.carolArea ? input.carolArea : '',
            areaId: input.areaId,
            area: input.area ? input.area : '',
            members: input.member,
            inactiveReason: input.inactiveReason,
            active: input.active,
            id: input.id,
            familyHeadImage: input.member.memberImage != null ? input.member.memberImage : '',
            familyHeadName: ((FamilyHeadName.nameArray !== '') ? FamilyHeadName.nameArray + '. ' + FamilyHeadName.fullNameWithSpace : FamilyHeadName.fullNameWithSpace) +
                ' ' + middleName + ' ' + lastName,
            familyImage: input.familyImage,
            occupation: input.member.occupation ? input.member.occupation : '',
            memberCount: input.memberCount
        }
        return Obj;
    }
}


export function getFullNamewithSpace(name) {
    const splitData = name ? name.split('.') : []
    let fullNameWithSpace = '';
    let sal = '';
    const len = splitData.length
    if (splitData.length > 1) {
        fullNameWithSpace = splitData[len - 1];
        var nameIndex = name.indexOf(fullNameWithSpace) - 1;
        sal = name.slice(0, nameIndex);

        // sal = splitData[0];
        // delete splitData[0]
        // splitData.forEach((name, i) => {
        //     fullNameWithSpace = fullNameWithSpace + name + ((i !== splitData.length - 1) ? ' ' : '');
        // });

    } else if (splitData.length > 0 && splitData.length === 1) {
        fullNameWithSpace = splitData[0]
    }
    let list = { 'nameArray': sal, 'fullNameWithSpace': fullNameWithSpace }
    return list;
}
export function getSpace(name){
    const splitData = name ? name.split('.') : []
 let sal = '';
 let full = ''; 
 let  fir = '';

const len = splitData.length;
if (splitData.length > 2) {
     fir = splitData[len - 1];
     full = splitData[1] ;
     sal = splitData[0];
    }
if (splitData.length > 1) {

 full = splitData[1] ;
 sal = splitData[0];
}
 else if (splitData.length > 0 && splitData.length === 1) {
 
    full = splitData[0]
}


  let list = { 'nameArray': sal, 'fullNameWithSpace': full, 'dot' : fir}
 return list;

}
export class CoversByChurchWithMemberJson {
    deserialize(input: any) {
        const obj = {
            coverId: input.coverId,
            coverNumber: input.coverNumber,
            displayName: input.displayName,
            email: input.email,
            memberId: input.memberId,
            memberImage: input.memberImage,
            mobileNumber: input.mobileNumber
        }
        return obj
    }
}