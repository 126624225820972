import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AuthGuard } from './core/guards/auth.guard';
export const appRoutes: Routes = [
   // {
   //    path: 'ecregistration',
   //     loadChildren: './ecclesia-registration-form/ecclesia-registration-form.module#EcclesiaRegistrationFormModule',
   //    // loadChildren: () => import('./ecclesia-registration-form/ecclesia-registration-form.module').then(m => m.EcclesiaRegistrationFormModule)
   // },
   {
      path: '',
      loadChildren: './session/session.module#SessionModule',
   },
  
   {
      path: 'print',
      loadChildren: './print-page/print-page.module#PrintPageModule',
      // loadChildren: () => import('./print-page/print-page.module').then(m => m.PrintPageModule),
      canActivate: [AuthGuard]
      // path: 'print',
   },
   {
      path: '',
      component: MainComponent,
      canActivate: [AuthGuard],
      runGuardsAndResolvers: 'always',
      children: [
         { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
         { path: 'member', loadChildren: './member/member.module#MemberManagementModule' },
         { path: 'report', loadChildren: './report/report.module#ReportModule' },
         { path: 'harvest', loadChildren: './harvest-fund/harvest-fund.module#HarvestFundModule' },
         { path: 'subscriptionPage', loadChildren: './subscription/subscription.module#SubscriptionModule' },
         { path: 'settings', loadChildren: './settings/settting.module#SetttingModule' },
         { path: 'communication', loadChildren: './communication/communication.module#CommunicationModule' },
         // {path:'group',loadChildren:'./group/group.module#
         {path: 'group' , loadChildren: './group-member/group-member.module#GroupMemberModule'},
         { path: 'offeringPage', loadChildren: './offering/offering.module#OfferingModule' },
         { path: 'donationPage', loadChildren: './donation/donation.module#DonationModule' },
         {path : 'expense', loadChildren: './expense/expense.module#ExpenseModule'},
         { path: 'events', loadChildren: './events/events.module#EventsModule' },
         {path: 'attendance', loadChildren:'./attendance/attendance.module#AttendanceModule'},
         { path: 'prayer', loadChildren: './prayer/prayer.module#PrayerModule' },
         // { path: 'approvalPage', loadChildren: './approval/approval.module#ApprovalModule' },
         { path: 'announcementPage', loadChildren: './announcement/announcement.module#AnnouncementModule' },
         { path: 'certificates', loadChildren: './certificates/certificates.module#CertificatesModule' }
      ]
   },
   {
      path: '**',
      redirectTo: '/error',
      pathMatch: 'full',
   },
  
]

@NgModule({
   imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
   exports: [RouterModule],
   providers: []
})
export class RoutingModule { }
