import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  public getAllGroup(): Observable<any> {
    return this.http.get<any>(this.authService._getAllGroup);
  }

  public getAllGroupById(groupId): Observable<any> {
    return this.http.get<any>(this.authService._getAllGroupById + '?id=' + groupId);
  }

  public getAllNonGroupMembers(sendData): Observable<any> {
    return this.http.post<any>(this.authService.getAllNonGroupMembers,sendData);
  }

  public saveGroup(data) {
    return this.http.post(this.authService._saveGroup, data);
  }

  public updateGroup(data) {
    return this.http.put(this.authService._updateGroup, data);
  }

  public sendSMS(Id) {
    return this.http.post(this.authService._sendSMS, Id);
  }

  public sendIndividualSms(data) {
    return this.http.post(this.authService._sendindividualsms, data);
  }

  public deleteGroup(Id) {
    return this.http.post(this.authService._deleteGroup + '?id=' + Id, '');
  }

}
