import { Injectable } from '@angular/core';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard'
  },
  {
    state: 'member',
    name: 'Members',
    type: 'sub',
    icon: 'people_alt',
    children: [
      { state: 'familyList', name: 'Family List' },
      { state: 'memberList', name: 'Member List' }
    ]
  },
  {
    state: 'subscriptionPage',
    name: 'Subscription',
    type: 'link',
    icon: 'attach_money'
  },
  {
    state: 'offeringPage',
    name: 'Offering',
    type: 'link',
    icon: 'monetization_on'
  },
  {
    state: 'donationPage',
    name: 'Donation',
    type: 'link',
    icon: 'monetization_on'
  },
  {
    state: 'expense',
    name: 'Expense',
    type: 'sub',
    icon: 'payments',
    children: [
      {state: 'expense-list',name: 'Expense List'},
      { state: 'expenseType',name: 'Expense Type'},
      {state: 'expenseSubType',name: 'Expense Sub Type'}
      //{ state: 'account',name: 'Account' },
    ]
  },
  {
    state: '',
    name: 'Auction/Pledges',
    type: 'sub',
    icon: 'eco',
    children: [
      { state: 'harvest', name: 'Auction List' },
      { state: 'harvest/outstanding', name: 'Outstanding' }
    ]
  },
  {
    state: 'prayer',
    name: 'Prayer',
    type: 'link',
    icon: 'meeting_room'
  },
  {
    state: '',
    name: 'Communication',
    type: 'sub',
    icon: 'contact_mail',
    children: [
      { state: 'communication/email', name: 'Compose' },
      // { state: 'communication/group-member', name: 'Group' },
      { state: 'communication/template', name: 'Template' }
    ]
  },
  {
    state: 'group',
    name: 'Group',
    type: 'link',
    icon: 'meeting_room'
  },
 
  {
    state: 'announcementPage',
    name: 'Announcement',
    type: 'link',
    icon: 'announcement'
  },
  {
    state: 'events',
    name: 'Events',
    type: 'link',
    icon: 'date_range'
  },
  {
    state: 'attendance',
    name: 'Attendance',
    type: 'link',
    icon: 'date_range'
  },
  {
    state: '',
    name: 'Certificates',
    type: 'sub',
    icon: 'note_add',
    children: [
      {state: 'certificates', name: 'Baptism Certificate'},
      // {state: 'certificates/marraigeCertificate', name: 'Marriage Certificate'}
    ]
  },
  // {
  //   state: 'approvalPage',
  //   name: 'Approval',
  //   type: 'link',
  //   icon: 'verified_user'
  // },

  {
    state: '',
    name: 'Import',
    type: 'sub',
    icon: 'import_export',
    children: [
      { state: 'member/importPage', name: 'Member' },
      { state: 'subscriptionPage/importSubscription', name: 'Subscription' },
      { state: 'harvest/import-auction', name: 'Auction/Pledges' }
    ]
  },
  {
    state: 'report',
    name: 'Report',
    // type: 'link',
    type: 'sub',
    icon: 'file_copy',
    children: [
      { state: 'attendance', name: 'Attendance' },
      { state: 'family-report', name: 'Family' },
      { state: 'member', name: 'Member' },
      { state: 'anniversary', name: 'Anniversary' },
      { state: 'subscription', name: 'Subscription' },
      { state: 'offering', name: 'Offering' },
      { state: 'donation', name: 'Donation' },
      {state: 'expense',name: 'Expense'},
      { state: 'log', name: 'Log' },
     
    ]
  },
  {
    state: 'settings',
    name: 'Settings',
    type: 'link',
    icon: 'settings'
  }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
  add(menu: any) {
    MENUITEMS.push(menu);
  }
}
